import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled, { css } from "styled-components";
import { getTrackColor } from "../tokens/colours";
import { transparentize } from "polished";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import getMinutes from "date-fns/getMinutes";
import getHours from "date-fns/getHours";
import { subHeading } from "../tokens/typography";

const chunkLengthInMins = 15;
const chunksOffsetToAddEachSide = 1;
const sidebarColumnsBeforeTimes = 1;
const headerRowsBeforeEvents = 1;

const Gantt = ({ headingComponent = "h2" }) => {
  const { mainEventsPage } = useStaticQuery(
    graphql`
      query {
        mainEventsPage: contentfulPage(
          contentful_id: { eq: "UZJdHLWSp4hBI4Kwkow4Q" }
        ) {
          id
          childContent {
            ... on ContentfulTrack {
              id
              name
              alternativeName
              descriptiveTitle
              slug
              page {
                slug
              }
              events {
                id
                slug
                title
                startDatetime
                endDatetime
                formattedStartTime: startDatetime(formatString: "HH:mm")
                formattedEndTime: endDatetime(formatString: "HH:mm")
                track {
                  id
                  alternativeName
                }
              }
            }
          }
        }
      }
    `
  );

  const tracks = mainEventsPage.childContent;

  let events = tracks.reduce((accumulator, currentValue) => {
    return [...accumulator, ...currentValue.events || []];
  }, []);

  const lastEndTime = new Date(
    Math.max(...events.map(event => new Date(event.endDatetime)))
  );
  const firstStartTime = new Date(
    Math.min(...events.map(event => new Date(event.startDatetime)))
  );
  const lengthOfDayInMinutes = Math.floor(
    Math.abs(firstStartTime - lastEndTime) / 1000 / 60
  );
  const chunks = lengthOfDayInMinutes / chunkLengthInMins;
  const visibleTimeslots = chunks + chunksOffsetToAddEachSide * 2;
  const timeSlotsArray = [...Array(visibleTimeslots)].map((x, i) => {
    return {
      time: addMinutes(
        addMinutes(
          firstStartTime,
          -(chunkLengthInMins * chunksOffsetToAddEachSide)
        ),
        chunkLengthInMins * i
      ),
    };
  });
  const headerRowTimesArray = timeSlotsArray.filter((timeslot, i) => {
    return i + 1 <= timeSlotsArray.length - 3;
  });

  return (
    <>
      <Heading as={headingComponent}>At a glance</Heading>
      <Wrapper>
        <Grid
          gridColumns={visibleTimeslots + sidebarColumnsBeforeTimes - 1}
          gridRows={tracks.length + headerRowsBeforeEvents}
        >
          <>
            {timeSlotsArray.map((timeSlot, i) => {
              return (
                <Line
                  aria-hidden="true"
                  key={i}
                  gridColumn={`${i + 1 + sidebarColumnsBeforeTimes}`}
                  isAlternate={getHours(timeSlot.time) % 2 === 0}
                />
              );
            })}
          </>

          <>
            {headerRowTimesArray.map((timeSlot, i) => {
              return (
                getMinutes(timeSlot.time) === 0 && (
                  <ColumnHeader
                    aria-hidden="true"
                    key={i}
                    gridColumn={`${i + 1 + sidebarColumnsBeforeTimes}/${i +
                      1 +
                      sidebarColumnsBeforeTimes +
                      4}`}
                    isAlternate={getHours(timeSlot.time) % 2 === 0}
                  >
                    {format(timeSlot.time, "HH:mm")}
                  </ColumnHeader>
                )
              );
            })}
          </>

          <>
            {tracks.map((track, idx) => (
              <Stripe
                aria-hidden="true"
                key={track.id}
                gridRowStart={idx + 1 + headerRowsBeforeEvents}
              />
            ))}
          </>

          <>
            {tracks.map((track, idx) => {
              const isAda = track.alternativeName === "Ada";
              const trackEvents = track.events;
              if (!trackEvents) return null;
              const trackNameForCss = track.alternativeName.toLowerCase();
              const trackEventsOrderedByStartTime = trackEvents.sort((a, b) => {
                return new Date(a.startDatetime) - new Date(b.startDatetime);
              });
              const trackEventsOrderedByEndTime = trackEvents.sort((a, b) => {
                return new Date(a.endDatetime) - new Date(b.endDatetime);
              });
              const trackLastEndEvent =
                trackEventsOrderedByEndTime[
                trackEventsOrderedByEndTime.length - 1
                ];
              const trackFirstStartEvent = trackEventsOrderedByStartTime[0];
              const trackFirstStartTime = trackFirstStartEvent.startDatetime;
              const trackLastEndTime = trackLastEndEvent.endDatetime;

              return (
                <>
                  <TrackRowHeader
                    key={track.id}
                    gridRowStart={idx + 2}
                    isAlternate={idx % 2 === 0}
                  >
                    <TrackAlternativeName>
                      {track.alternativeName}
                    </TrackAlternativeName>
                    <TrackName>{track.name}</TrackName>
                  </TrackRowHeader>
                  {trackEvents.map(event => {
                    return (
                      <Event
                        key={event.id}
                        gridRowStart={idx + 1 + headerRowsBeforeEvents}
                        trackName={trackNameForCss}
                        linkUrl={
                          !isAda &&
                          `/${track.page[0].slug}/${track.slug}/${event.slug}/`
                        }
                        title={!isAda && event.title}
                        startTime={new Date(event.startDatetime)}
                        endTime={new Date(event.endDatetime)}
                        formattedStartTime={!isAda && event.formattedStartTime}
                        formattedEndTime={!isAda && event.formattedEndTime}
                        firstStartTime={firstStartTime}
                      />
                    );
                  })}
                  {isAda && (
                    <Event
                      gridRowStart={idx + 2}
                      trackName={trackNameForCss}
                      linkUrl={`${track.page[0].slug}/${track.slug}/`}
                      title={track.descriptiveTitle}
                      startTime={new Date(trackFirstStartTime)}
                      endTime={new Date(trackLastEndTime)}
                      firstStartTime={firstStartTime}
                      formattedStartTime={
                        trackFirstStartEvent.formattedStartTime
                      }
                      formattedEndTime={trackLastEndEvent.formattedEndTime}
                    />
                  )}
                </>
              );
            })}
          </>
        </Grid>
      </Wrapper>
    </>
  );
};

function Event({
  gridRowStart,
  trackName,
  linkUrl = null,
  title = null,
  startTime,
  endTime,
  formattedStartTime = null,
  formattedEndTime = null,
  firstStartTime,
  className,
}) {
  const lengthInMinutes = Math.floor(Math.abs(startTime - endTime) / 1000 / 60);
  const lengthInChunks = lengthInMinutes / chunkLengthInMins;
  const timeFromStartOfDay = Math.abs(firstStartTime - startTime) / 1000 / 60;
  const gridStartColumn =
    timeFromStartOfDay / chunkLengthInMins +
    chunksOffsetToAddEachSide +
    sidebarColumnsBeforeTimes +
    1;
  const gridEndColumn = gridStartColumn + lengthInChunks;
  const gridColumn = `${gridStartColumn}/${gridEndColumn}`;

  return (
    <EventWrapper
      gridRowStart={gridRowStart}
      gridColumn={gridColumn}
      trackName={trackName}
      to={linkUrl}
      className={className}
      as={linkUrl ? Link : "div"}
      aria-hidden={linkUrl ? false : true}
    >
      {title && <EventTitle>{title}</EventTitle>}
      {formattedStartTime && formattedEndTime && (
        <EventTime>{`${formattedStartTime}-${formattedEndTime}`}</EventTime>
      )}
    </EventWrapper>
  );
}

const Heading = styled.h2`
  ${subHeading};
  margin-top: 3rem;
`;

const Wrapper = styled.div`
  width: 100vw;
  position: relative;
  right: 50%;
  left: 50%;
  margin-right: -50vw;
  margin-bottom: 3rem;
  margin-left: -50vw;
  padding-bottom: 1rem;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto repeat(
      ${({ gridColumns }) => gridColumns},
      minmax(1%, 1fr)
    );
  grid-template-rows: auto repeat(${({ gridRows }) => gridRows}, auto);
  min-width: 40em;
  margin-top: 2rem;
`;

const alternateBackground = ({ isAlternate }) =>
  !isAlternate &&
  css`
    background-color: ${({ theme }) =>
      transparentize(0.5, theme.palette.black)};
  `;

const Line = styled.span`
  ${alternateBackground};

  grid-column: ${({ gridColumn }) => gridColumn};
  grid-row: ${headerRowsBeforeEvents + 1} / -1;
  border-left: 1px dashed
    ${({ theme }) => transparentize(0.75, theme.palette.white)};
`;

const Stripe = styled.span`
  grid-row: ${({ gridRowStart }) => gridRowStart};
  grid-column: 1 / -1;
  z-index: -1;

  border-top: 1px dashed
    ${({ theme }) => transparentize(0.75, theme.palette.white)};

  &:nth-child(even) {
    background-color: ${({ theme }) =>
    transparentize(0.5, theme.palette.black)};
  }
`;

const zebraStripe = ({ isAlternate }) =>
  !isAlternate &&
  css`
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: ${({ theme }) =>
      transparentize(0.5, theme.palette.black)};
    }
  `;

const TrackRowHeader = styled.div`
  ${zebraStripe};

  grid-row: ${({ gridRowStart }) => gridRowStart};
  grid-column: 1 / 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  left: 0;
  margin: 0;
  padding: 0.5em 0.75em;
  font-size: 0.8em;
  border-top: 1px dashed
    ${({ theme }) => transparentize(0.75, theme.palette.white)};
  background-color: ${({ theme }) => theme.palette.oxfordshireBlue};
`;

const TrackAlternativeName = styled.h3`
  margin-top: 0;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
`;

const TrackName = styled.div`
  margin: 0;
  font-weight: 300;
`;

const ColumnHeader = styled.div`
  ${alternateBackground};

  grid-column: ${({ gridColumn }) => gridColumn};
  grid-row: 1;
  padding: 0.75em 0.5em;
  font-size: 0.6em;
  color: white;
  position: sticky;
  top: 0;
`;

const EventWrapper = styled(Link)`
  grid-row: ${({ gridRowStart }) => gridRowStart};
  grid-column: ${({ gridColumn }) => gridColumn};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  margin-left: 1px;
  padding: 0.25em 0.75em;
  font-size: 0.8em;
  line-height: 1.2;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.white};
  border: 2px solid ${({ trackName }) => getTrackColor(trackName)};
  border-radius: 0.5em;
  background-color: transparent;
  background-color: ${({ trackName }) =>
    transparentize(trackName === "ada" ? 1 : 0.25, getTrackColor(trackName))};

  &:empty {
    padding-right: 0;
    padding-left: 0;
    background-color: ${({ trackName }) =>
    transparentize(0.25, getTrackColor(trackName))};
  }
`;

const EventTitle = styled.div`
  font-weight: bold;
`;

const EventTime = styled.div`
  margin-top: 0.25em;
`;

export default Gantt;
